import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "./rolling-stock.css"
import "./catalog.css"

type RollingStockPageProps = {
  data: {
    content: {
      pageSections: Array<{
        sectionName: string
        sectionItems: Array<{
          price: string
          name: string
          description: string
          image: {
            asset: {
              url: string
              title: string
            }
          }
        }>
      }>
    }
  }
}

const RollingStockPage = ({ data }: RollingStockPageProps) => (
  <Layout>
    <SEO title="Home" />
    <div className="container" style={{ flex: 1 }}>
      <p>
        Creative Train Company has a wide collection of rolling stock available
        for your train! All of our trains are fit for a 7.25" and 7.5" guage
        track.
      </p>
      <p>All items are ready to ship and do not include trucks.</p>
      <div className="catalog">
        {data.content.pageSections.map(pageSection => (
          <>
            <header className="catalog__section-header">
              {pageSection.sectionName}
            </header>
            <section>
              {pageSection.sectionItems.map(sectionItem => (
                <div className="catalog__item">
                  <img src={sectionItem.image?.asset?.url} />
                  <div className="catalog__item-contents">
                    <div className="catalog__item-name">{sectionItem.name}</div>
                    <div className="catalog__item-description">
                      {sectionItem.description}
                    </div>
                    <div className="catalog__item-price">
                      {sectionItem.price}
                    </div>
                  </div>
                </div>
              ))}
            </section>
          </>
        ))}
      </div>
    </div>
  </Layout>
)

export default RollingStockPage

export const query = graphql`
  query RollingStockPageQuery {
    content: sanityCatalogContent(
      pageId: { current: { eq: "rolling-stock" } }
    ) {
      pageSections {
        sectionItems {
          price
          name
          image {
            asset {
              url
              title
            }
          }
          description
        }
        sectionName
      }
    }
  }
`
